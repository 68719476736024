import React from "react"
import { Link, graphql } from "gatsby"
import {
  BlogPostListItem,
  GridBox,
  GridSeparator,
  GridSpacer,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../components/seo"

export default props => {
  const { data } = props
  return (
    <>
      <SEO title={`Posts about ${data.prismicCategory.data.category}`} />
      <GridBox type={5} colSpans={[[2, 7], [2, 7], [5, 11]]} withBgContainer>
        <Title standalone level={1}>
          Posts about {data.prismicCategory.data.category}
        </Title>
      </GridBox>
      <GridBox type={5} colSpans={[[2, 8], [2, 8], [5, 11]]} withBgContainer>
        <Text standalone italic>
          <Link to={`/blog/`} style={{ whiteSpace: "pre" }}>
            all posts
          </Link>
          &nbsp;&nbsp;{" "}
          {data.allPrismicCategory.nodes.map(({ data, uid }) => (
            <>
              <Link to={`/blog/category/${uid}`} style={{ whiteSpace: "pre" }}>
                {data.category}
              </Link>
              &nbsp;&nbsp;{" "}
            </>
          ))}
        </Text>
      </GridBox>
      <GridSpacer desktop={40} mobile={40} tablet={40} type={5} />
      {data.allPrismicBlogPost.nodes.map(({ data, uid }) => (
        <>
          <GridSeparator />
          <BlogPostListItem
            href={`/blog/${uid}`}
            image={data.featured_image.url}
            title={data.title.text}
            key={uid}
          />
        </>
      ))}
    </>
  )
}

export const query = graphql`
  query($uid: String!) {
    allPrismicCategory {
      nodes {
        data {
          category
        }
        uid
      }
    }

    allPrismicBlogPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: {
        data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
      }
    ) {
      nodes {
        data {
          featured_image {
            alt
            url
          }
          title {
            text
          }
        }
        uid
      }
    }

    prismicCategory(uid: { eq: $uid }) {
      data {
        category
      }
    }
  }
`
